.assembly-panel-grid {
  display: grid;
  grid-template-areas:
    "assembly_panel_title assembly_panel_title"
    "joint_label joint_input"
    "concept_label concept_input"
    "message_error message_error"
    "btn_block btn_block";
  gap: 1em;
  flex: 1;
}

.assembly-panel-title {
  grid-area: assembly_panel_title;
  text-align: center;
}

.assembly-panel-buttons {
  grid-area: btn_block;
  display: flex;
  justify-content: space-between;
}

