.header-container {
  display: grid;
  grid-template-columns: 20% auto 15%;
  grid-template-areas: "image navbar session";
  margin: 0 1em;
}

.scaf-logo {
  grid-area: image;
  margin: 1em 0;
}

.menu {
  grid-area: navbar;
  margin: auto 0;
}

.user-session {
  grid-area: session;
  margin: auto 0;
  text-align: right;
}

.scaf-logo img {
  height: 50px;
}

.nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0;
  list-style: none;
}

.nav-sublist {
  display: none;
  position: fixed;
  z-index: 1;
  top: 3.8em;
  background-color: #ffffff;
  padding: 0 0.6em;
  list-style: none;
}

.nav-list > li:hover .nav-sublist {
  display: block;
}

.logout-image {
  color: #000000;
  height: auto;
  width: 1.6em;
  margin: 0 0.3em;
}

.mobile-menu-btn {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cf2929;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 60%;
  transition: all 0.3s ease-in-out 0.3s;
}

.mobile-menu-logo {
  color: #ffffff;
  width: 2em;
  height: 2em;
}

.mobile-menu-btn.show {
  background-image: url("../static/MobileMenu.svg");
}

.mobile-menu-btn.hide {
  background-image: url("../static/Close.svg");
}

@media screen and (max-width: 1275px) {
  .header-container {
    display: grid;
    grid-template-columns: 15% auto 20%;
    grid-template-areas: "image navbar session";
  }

  .user-session {
    grid-area: session;
    margin: auto 0.5em;
    text-align: right;
  }
}

@media screen and (max-width: 900px) {
  .header-container {
    display: grid;
    grid-template-areas: "image session mobile";
    grid-template-columns: 20% auto 15%;
  }

  .nav-list {
    display: block;
    padding: 0;
  }

  .scaf-logo {
    margin: 0.7em;
  }

  .mobile-menu-btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    grid-area: mobile;
    margin: auto;
  }

  .menu {
    display: block;
    position: fixed;
    top: 65px;
    left: -280px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100vh;
    width: 280px;
    overflow: auto;
    transition: left 0.5s ease-in-out 0.3s;
  }

  .menu.displayed {
    left: 0;
  }

  .nav-sublist {
    display: block;
    position: initial;
    background-color: transparent;
  }
}

@media screen and (max-width: 400px) {
  .menu {
    top: 60px;
    left: -280px;
    width: 220px;
  }

  .scaf-logo img {
    height: 40px;
  }

  .mobile-menu-btn {
    width: 38px;
    height: 38px;
  }
}
