.nav-item {
  font-size: 1.1em;
  color: #464b4e;
  font-family: "Asap", system-ui;
  text-align: center;
  border-radius: 15px;
  margin: 0 0.8em;
  background-color: transparent;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out 0.3s;
}

.nav-item:hover {
  background-color: #cf2929;
  color: #ffffff;
}

.nav-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0.5em 0.6em;
}

@media screen and (max-width: 900px) {
  .nav-item {
    font-size: 1em;
    border-radius: 0;
    margin: 0;
  }
}
