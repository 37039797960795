.register-qr-grid {
    display: grid;
    max-width: 800px;
    grid-template-areas:
      "register_qr_title register_qr_title"
      "joint_label joint_input"
      "apartments_label apartments_input"
      "qr_code_label qr_code_input"
      "message_error message_error"
      "btn_block btn_block";
    gap: 1em;
    flex: 1;
  }
  
  .register-qr-title {
    grid-area: register_qr_title;
    text-align: center;
  }

  .apartments-label {
    grid-area: apartments_label;
    margin: auto 0;
  }

  .apartments-input {
    grid-area: apartments_input;
  }

  .qr-code-label {
    grid-area: qr_code_label;
    margin: auto 0;
  }

  .qr-code-input {
    grid-area: qr_code_input;
    width: 100%;
  }
  
  