.vote-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "vote_title"
    "concept_description"
    "options_block"
    "message_error"
    "btn_block";
  gap: 1em;
  flex: 1;
}

.vote-title {
  grid-area: vote_title;
  text-align: center;
}

.concept-description {
  grid-area: concept_description;
  text-align: center;
}
