footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 4em;
    background-color: #CF2929;
    font-family: 'Asap', system-ui;
    color: #FFFFFF;
    text-align: center;
}