.scaf-swal-container {
  font-family: "Asap", system-ui;
}

.scaf-swal-input {
  height: 30px;
  padding: 0.6em;
  border: 1px solid #cacaca;
  border-radius: 15px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px #d4d4d4;
  text-align: center;
  font-family: inherit;
  font-size: 1em;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.scaf-swal-btn {
  margin: 0 1em;
}

.scaf-swal-validation-message {
  background: none;
}
