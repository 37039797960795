.generate-question-grid {
  display: grid;
  max-width: 800px;
  grid-template-areas:
    "generate_question_title generate_question_title"
    "joint_label joint_input"
    "question_label question_input"
    "answers_quantity_label answers_quantity_input"
    "answers_container answers_container"
    "message_error message_error"
    "btn_block btn_block";
  grid-template-columns: 30% auto;
  gap: 1em;
  flex: 1;
}

.generate-question-title {
  grid-area: generate_question_title;
  text-align: center;
}

.question-label {
  grid-area: question_label;
  align-self: center;
}

.question-input {
  grid-area: question_input;
}

.answers-quantity-label {
  grid-area: answers_quantity_label;
  margin: auto 0;
}

.answers-quantity-input {
  grid-area: answers_quantity_input;
}

.answers-container {
  grid-area: answers_container;
  height: 230px;
  overflow-y: scroll;
}

.answers-container::-webkit-scrollbar {
  display: none;
}

.answer {
  display: grid;
  grid-template-areas: "answer_label answer_input";
  grid-template-columns: 30% auto;
  gap: 1em;
  margin: 1em 0;
  flex: 1;
}

.answer-label {
  margin: auto 0;
}
