.statement-account-grid {
  display: grid;
  grid-template-areas:
    "statement_account_title statement_account_title"
    "joint_label joint_input"
    "owner_label owner_input"
    "message_error message_error"
    "btn_block btn_block";
  gap: 1em;
  flex: 1;
}

.statement-account-title {
  grid-area: statement_account_title;
  text-align: center;
}
