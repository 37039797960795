.informational-message {
    text-align: center;
    font-family: 'Asap', system-ui;
    font-weight: normal;
}

.joint-name {
    font-weight: bold;
}

.register-btn {
    width: 50%;
    align-self: center;
    text-align: center;
}