.bills-grid {
  display: grid;
  grid-template-areas:
    "bills_title bills_title"
    "joint_label joint_input"
    "date_label date_input"
    "message_error message_error"
    "btn_block btn_block";
  gap: 1em;
  flex: 1;
}

.bills-title {
  grid-area: bills_title;
  text-align: center;
}
