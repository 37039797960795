.service-article {
  border-radius: 15px;
  overflow: hidden;
}

.article-image {
  display: flex;
  align-items: center;
  max-height: 200px;
  overflow: hidden;
  object-fit: cover;
  margin: 0;
}

.article-title {
  text-align: center;
  margin: 0.5em 0;
}

.article-list {
  font-family: "Noto Sans", system-ui;
  list-style: circle;
  padding: 0 2.5em;
}
