.input-container {
  display: flex;
  justify-content: space-evenly;
}

.quantity-input {
  width: 20%;
  text-align: center;
}

.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.functional-btn {
  height: 1.5em;
  width: 1.5em;
  color: #000000;
}
