.monthly-payments-grid {
    display: grid;
    grid-template-areas:
      "monthly_payments_title monthly_payments_title"
      "joint_label joint_input"
      "date_label date_input"
      "message_error message_error"
      "btn_block btn_block";
    gap: 1em;
    flex: 1;
  }
  
  .monthly-payments-title {
    grid-area: monthly_payments_title;
    text-align: center;
  }