.hero {
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image {
  margin: 0;
}

.who-we-are {
  display: grid;
  grid-template-areas:
    "who_we_are_title"
    "who_we_are_description"
    "who_we_are_mision_title"
    "who_we_are_mision_description"
    "who_we_are_vision_title"
    "who_we_are_vision_description";
  margin: 1em;
}

.who-we-are-title {
  grid-area: who_we_are_title;
  text-align: center;
}

.who-we-are-description,
.who-we-are-mision-title,
.who-we-are-mision-description,
.who-we-are-vision-title,
.who-we-are-vision-description,
.services-description,
.article-description {
  text-align: justify;
}

.who-we-are-description {
  grid-area: who_we_are_description;
}

.who-we-are-mision-title,
.who-we-are-vision-title {
  font-family: "Asap", system-ui;
  color: #cf2929;
}

.who-we-are-mision-title {
  grid-area: who_we_are_mision_title;
}

.who-we-are-vision-title {
  grid-area: who_we_are_vision_title;
}

.who-we-are-mision-description {
  grid-area: who_we_are_mision_description;
}

.who-we-are-vision-description {
  grid-area: who_we_are_vision_description;
}

.services {
  display: grid;
  grid-template-areas:
    "services_title services_title"
    "services_description services_description"
    "service_one service_two"
    "service_three service_three";
  gap: 1em;
  margin: 1em;
}

.services-title {
  grid-area: services_title;
  text-align: center;
}

.services-description {
  grid-area: services_description;
}

.service-one {
  grid-area: service_one;
}

.service-two {
  grid-area: service_two;
}

.service-three {
  grid-area: service_three;
}

.clients {
  display: grid;
  grid-template-areas:
    "clients_title"
    "clients_images_container";
    margin: 1em;
}

.clients-title {
  grid-area: clients_title;
  text-align: center;
}

.clients-images-container {
  grid-area: clients_images_container;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.client-image {
  animation-name: translateClient;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
}

@keyframes translateClient {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-950px);
  }
}

@keyframes translateClient900px {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1350px);
  }
}

@keyframes translateClient400px {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1850px);
  }
}

@media screen and (max-width: 900px) {
  .services {
    grid-template-areas:
      "services_title"
      "services_description"
      "service_one"
      "service_two"
      "service_three";
  }

  .client-image {
    animation-name: translateClient900px;
  }
}

@media screen and (max-width: 400px) {
  .client-image {
    animation-name: translateClient400px;
  }
}
