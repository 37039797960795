.launch-page-grid {
  display: grid;
  grid-template-areas:
    "apartments_registered_table votes_history_table"
    "zoom_btn vote_btn";
  gap: 1em;
  flex: 1;
}

.apartments-registered-table {
    grid-area: apartments_registered_table;
}

.votes-history-table {
    grid-area: votes_history_table;
}

.apartments-registered-table > thead,
.votes-history-table > thead {
    border-bottom: black 1px solid;
}

.table-title {
    font-weight: 100;
}

.apartments-registered-table > thead > tr > th,
.votes-history-table > thead > tr > th {
    height: auto;
}

.apartments-registered-table > tbody,
.votes-history-table > tbody {
    max-height: max-content;
}

.zoom-btn {
    grid-area: zoom_btn;
    margin: auto 0.3em;
}

.vote-btn {
    grid-area: vote_btn;
    margin: auto 0.3em;
}

@media screen and (max-width: 900px) {
    .launch-page-grid {
        flex: 0;
      }
  }

