table {
  /* max-height: 24em; */
  margin: 1.2em 0.3em;
  font-family: "Asap", system-ui;
  font-size: 0.9em;
  text-align: center;
  border-collapse: collapse;
}

.table-header {
  background-color: #cf2929;
  color: #ffffff;
}

tbody {
  max-height: 20em;
  display: block;
  overflow-y: scroll;
}

tr {
  display: flex;
}

td,
th {
  height: 3em;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

th {
  padding: 0 0.3em;
}

tbody tr:nth-child(even) {
  background-color: #eeeeee;
}

tbody::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  tbody td {
    height: auto;
    margin: 0.2em;
  }
}
