.login-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "login_title"
    "username_block"
    "password_block"
    "failed_login"
    "btn_block";
  gap: 1em;
  flex: 1;
}

.login-title {
  grid-area: login_title;
  text-align: center;
}

.username-block {
  grid-area: username_block;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.password-block {
  grid-area: password_block;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.failed-login {
  grid-area: failed_login;
}
