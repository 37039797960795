.assembly-reporter-grid {
  display: grid;
  grid-template-areas:
    "assembly_reporter_title assembly_reporter_title"
    "joint_label joint_input"
    "concept_label concept_input"
    "options_label options_block"
    "message_error message_error"
    "btn_block btn_block";
  gap: 1em;
  flex: 1;
}

.assembly-reporter-title {
  grid-area: assembly_reporter_title;
  text-align: center;
}

.format-options-labels {
  grid-area: options_label;
}
