.balance-age-grid {
    display: grid;
    grid-template-areas:
      "balance_age_title balance_age_title"
      "joint_label joint_input"
      "message_error message_error"
      "btn_block btn_block";
    gap: 1em;
    flex: 1;
  }
  
  .balance-age-title {
    grid-area: balance_age_title;
    text-align: center;
  }