.quick-access-buttons {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.quick-btn {
  -webkit-appearance: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 1em;
  margin: 0.3em 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.quick-btn-logo {
  color: #000000;
  height: auto;
  width: 2.5em;
}

.quick-lbl {
  margin: 0.3em;
  width: 8em;
}
