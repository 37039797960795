.not-found-container {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.not-found-404 {
    font-size: 10em;
}

.not-found-message,
.not-found-404 {
  text-align: center;
  font-family: "Asap", system-ui;
}

.not-found-btn {
  align-self: center;
}
