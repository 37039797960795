body {
  margin: 0;
  font-size: 16px;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0;
}

h1 {
  font-size: 2.5em;
  font-weight: normal;
}

h2 {
  font-size: 2.2em;
  margin: 0;
}

h3 {
  font-size: 1.9em;
  font-weight: normal;
  margin: 0;
}

h4 {
  font-size: 1.6em;
  font-weight: normal;
  margin: 0;
}

h5 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0;
}

.title {
  text-transform: uppercase;
  font-family: "Asap", system-ui;
  font-weight: 100;
}

p {
  font-family: "Noto Sans", system-ui;
}

.welcome-message,
.completion-message {
  text-align: center;
  font-family: "Asap", system-ui;
}

.userform {
  display: flex;
  justify-content: center;
  font-family: "Asap", system-ui;
  margin: 1.2em 0.3em;
}

.square-form {
  max-width: 500px;
  height: auto;
  border-color: #cf2929;
  border-style: solid;
  border-radius: 15px;
  border-width: 3px;
  padding: 0.9em;
}

.error-message {
  font-size: 1em;
  font-weight: normal;
  text-align: center;
  color: #cf2929;
  margin: 1em;
}

.btn {
  -webkit-appearance: none;
  font-family: "Asap", system-ui;
  font-size: 1.3em;
  border: none;
  border-radius: 15px;
  padding: 0.5em 0.9em;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.scaf-accept-btn {
  background-color: #cf2929;
  color: #ffffff;
}

.scaf-reject-btn {
  background-color: #dfdfdf;
  color: #000000;
}

.userform-input-text {
  box-sizing: border-box;
  width: 60%;
  height: 30px;
  padding: 0.6em;
  border: 1px solid #cacaca;
  border-radius: 15px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px #d4d4d4;
  font-family: inherit;
  font-size: 1em;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.userform-select {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  padding: 0 30px 0 10px;
  color: #000000;
  background-color: #fefefe;
  border: 1px solid #cacaca;
  font-family: inherit;
  font-size: 0.9em;
  background-image: url("../static/downArrow.svg");
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: 95% 50%;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.userform-textarea {
  box-sizing: border-box;
  padding: 0.6em;
  border: 1px solid #cacaca;
  border-radius: 15px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px #d4d4d4;
  font-family: inherit;
  font-size: 1em;
  overflow: hidden;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.userform-input-text:focus,
.userform-select:focus,
.userform-textarea:focus {
  outline: none;
  box-shadow: 0 0 5px #cacaca;
}

.joint-label {
  grid-area: joint_label;
  margin: auto 0;
}

.joint-input {
  grid-area: joint_input;
  margin: auto;
}

.owner-label {
  grid-area: owner_label;
  margin: auto 0;
}

.owner-input {
  grid-area: owner_input;
  margin: auto;
}

.apartment-input {
  grid-area: apartment_input;
  margin: auto;
}

.date-label {
  grid-area: date_label;
  margin: auto 0;
}

.date-input-container {
  grid-area: date_input;
  display: flex;
  justify-content: space-between;
}

.date-input {
  width: 50%;
  background-position: 90% 50%;
  margin: 0 0.1em;
}

.concept-label {
  grid-area: concept_label;
}

.concept-input {
  grid-area: concept_input;
}

.bad-request {
  grid-area: message_error;
}

.btn-block {
  grid-area: btn_block;
  margin: auto;
}

.options-block {
  grid-area: options_block;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.option {
  text-align: center;
  margin: .5em .8em;
}

.owner-form-input {
  width: -webkit-fill-available;
  text-align: center;
}

.owner-form-joint-select,
.assembly-form-select {
  background-position: 98% 50%;
}

@media screen and (max-width: 400px) {
  body {
    font-size: 12px;
  }
}
