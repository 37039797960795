.building {
  display: grid;
  grid-template-areas:
    "s1 s2 s3"
    "s4 s5 s6"
    "s7 s8 s9";
  align-self: center;
}

.square {
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.2em;
  margin: 0.5em;
  background-color: #000000;
  animation: opacity 2s ease-in-out infinite;
}

.s-1 {
    animation-delay: 0s;
}

.s-2 {
    animation-delay: 0.2s;
}

.s-3 {
    animation-delay: 0.5s;
}

.s-4 {
    animation-delay: 0.8s;
}

.s-5 {
    animation-delay: 1s;
}

.s-6 {
    animation-delay: 0.8s;
}

.s-7 {
    animation-delay: 0.5s;
}

.s-8 {
    animation-delay: 0.2s;
}

.s-9 {
    animation-delay: 0s;
}

@keyframes opacity {
  0%, 100% {
    opacity: initial;
  }
  50% {
    opacity: 0.2;
  }
}
