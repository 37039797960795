.reverse-register-grid {
    display: grid;
    max-width: 800px;
    grid-template-areas:
      "reverse_register_title reverse_register_title"
      "joint_label joint_input"
      "apartments_label apartments_input"
      "message_error message_error"
      "btn_block btn_block";
    gap: 1em;
    flex: 1;
  }
  
  .reverse-register-title {
    grid-area: reverse_register_title;
    text-align: center;
  }

  .apartments-label {
    grid-area: apartments_label;
    margin: auto 0;
  }

  .apartments-input {
    grid-area: apartments_input;
  }