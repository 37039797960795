.quorum-grid {
  max-width: initial;
  display: grid;
  grid-template-areas:
    "joint_input concept_input"
    "message_error message_error";
  grid-template-columns: 50% auto;
  gap: 1em;
  flex: 1;
}

.concept-description {
  text-align: center;
  margin: 0 0.3em;
}

.quorum-register-container,
.quorum-answers-container {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.quorum-answers-container {
    height: 220px;
  }

.quorum-answer-container {
  max-width: 1000px;
  display: grid;
  margin: 1.2em 0.3em;
  font-family: "Asap", system-ui;
  grid-template-columns: 70% 15% auto;
  gap: 1em;
  flex: 1;
}

.quorum-answer-description {
  justify-self: start;
}

.quorum-answer-percentage,
.quorum-answer-total {
  justify-self: end;
}
