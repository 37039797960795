.bills-owner-grid {
  max-width: initial;
  display: grid;
  grid-template-areas:
    "bills_owner_title joint_input apartment_input"
    "message_error message_error message_error";
  grid-template-columns: 30% auto 20%;
  gap: 1em;
  flex: 1;
}

.bills-owner-title {
  grid-area: bills_owner_title;
}

@media screen and (max-width: 900px) {
  .bills-owner-grid {
    grid-template-areas:
      "bills_owner_title bills_owner_title"
      "joint_input apartment_input"
      "message_error message_error";
    grid-template-columns: 50% auto;
  }

  .bills-owner-title {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .bills-owner-grid {
    grid-template-areas:
      "bills_owner_title"
      "joint_input"
      "apartment_input"
      "message_error";
    justify-content: center;
    grid-template-columns: auto;
  }
}
